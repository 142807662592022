import { tivio } from '@tivio/core-js'
import { useEffect, useState } from 'react'

import { useError } from './useError'

import type { PaginationInterface, SubscribeToTaggedVideosOptions, Video } from '@tivio/types'


/**
 * Returns videos with given tag ids
 * @param tagIds - tag ids
 * @param options - subscription options
 */
const useTaggedVideos = (
    tagIds: string[] | null,
    options: SubscribeToTaggedVideosOptions = {},
    organizationPath?: string,
) => {
    const [ pagination, setPagination ] = useState<PaginationInterface<Video> | null>(null)
    const { error, raiseError } = useError()

    useEffect(() => {
        if (tagIds?.length && tagIds.every(tagId => !!tagId) && options) {
            const disposer = tivio.subscribeToTaggedVideos(
            // TODO - resolve tags count another way
                tagIds.slice(0, 10),
                (error, data) => {
                    if (error) {
                        raiseError(error)
                    }

                    if (data) {
                        if (options.fetchTags) {
                        // TODO - hack so mobx reacts on tags changes, remove this with custom comparer
                            data.items.forEach(video => {
                                video.tags?.forEach?.(tag => ({
                                    name: tag.name,
                                    type: tag.type,
                                    metadata: tag.metadata,
                                    color: tag.color,
                                }))
                            })
                        }

                        setPagination({
                            items: data.items,
                            fetchMore: data.fetchMore,
                            hasNextPage: data.hasNextPage,
                            loading: data.loading,
                            initialLoading: data.initialLoading,
                        })
                    }
                },
                options,
                organizationPath,
            )

            return () => {
                disposer?.()
            }
        }
    }, [
        // if pass array with string then it will cause loop, so you need to spread strings
        JSON.stringify(tagIds),
        JSON.stringify(options),
    ])


    return {
        pagination,
        error,
    }
}

export {
    useTaggedVideos,
}
