import { Chip, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab'
import { notEmptyFilter } from '@tivio/common'
import { TagDocument } from '@tivio/firebase'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Tag } from '../../store/Tag'
import { useTags } from '../hooks/dataHooks/useTags'

import type firebase from 'firebase/app'
import { createTag } from '../../creator/tags.creator'


interface Props {
    /**
     * If not presented, then all tags are used as options
     */
    options?: Tag[]
    selectedTags: firebase.firestore.DocumentReference<TagDocument>[]
    onTagChange?: (tags: Tag[]) => void
    /**
     * Autocomplete component props
     */
    [key: string]: any
}

/**
 * ! tags cannot be paginated, otherwise it may return invalid results
 */
const tagsRefsToTags = (tagRefs: firebase.firestore.DocumentReference<TagDocument>[], tags: Tag[]): Tag[] => {
    return tagRefs
        .map(tagRef => {
            return tags.find(tag => tag.id === tagRef.id)
        })
        .filter(notEmptyFilter)
}

const TagsAutocomplete: React.FC<Props> = (props) => {
    const { options, selectedTags, onTagChange, ...autocompleteProps } = props
    const { getAllTags, getGlobalTags } = useTags()
    const [tagOptions, setTagOptions] = useState<Tag[]>([])
    const [initialSelectedTags, setInitialSelectedTags] = useState<Tag[]>([])
    const [t] = useTranslation()

    const initTagOptions = useCallback(async () => {
        if (options?.length) {
            setTagOptions(options)
        }
        else {
            const allTags = await getAllTags()
            const globalTags = await getGlobalTags()

            const allTagOptions = allTags ? allTags : []
            const globalTagOptions = globalTags ? globalTags : []
            const initialTags: Tag[] = []
            for await (const tagRef of selectedTags) {
                const tagDoc = await tagRef.get()
                if (tagDoc) {
                    const tagData = tagDoc.data()
                    if (tagData) {
                        initialTags.push(createTag(tagDoc.ref, tagData))
                    }
                }
            }
            setInitialSelectedTags(initialTags)
            setTagOptions([...globalTagOptions, ...allTagOptions])
        }
    }, [getAllTags, getGlobalTags, options])

    useEffect(() => {
        initTagOptions()
        // We want to call only on mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTagChange = (tag: Tag[] | null) => {
        if (!tag) {
            return
        }

        onTagChange?.(tag)
    }

    const renderInput = (params: any) => {
        return (
            <TextField
                {...params}
                size="small"
                placeholder={t('Start typing')}
                variant="outlined"
            />
        )
    }

    const renderTags = (value: Tag[], getTagProps: AutocompleteGetTagProps) => {
        return (
            value.map((option, index) => (
                <Chip
                    variant="outlined"
                    size="small"
                    label={option.name?.en}
                    key={`chip-${option.tagId}`}
                    {...getTagProps({ index })}
                />
            ))
        )
    }

    return (
        <Autocomplete
            {...autocompleteProps}
            options={tagOptions}
            openOnFocus
            multiple
            filterSelectedOptions
            disableCloseOnSelect
            value={tagsRefsToTags(selectedTags, [...tagOptions, ...initialSelectedTags])}
            // TODO not the best to fallback to ''
            getOptionLabel={(option) => option.getNameTranslation.en ?? ''}
            onChange={(e, tag) => handleTagChange(tag)}
            renderInput={renderInput}
            renderTags={renderTags}
        />
    )
}

export {
    TagsAutocomplete,
}
